import { Base64 } from 'js-base64';
import isPlainObject from 'lodash/isPlainObject';
import { IMAGE_SRCSET } from '@/constants/images';
import { param } from '@/utils/url';

export const ASSET_PREFIX = 'img/asset';
export const LOCAL_ASSET_PREFIX = 'storage';

export function getImageRealPath(path, container) {
  let realPath = path;
  if (isPlainObject(path)) {
    realPath = path.id;
  }
  if (realPath.indexOf('/') === 0) {
    realPath = realPath.replace(/^\//, '');
  }
  const pathArr = realPath.split('::');

  let str;
  if (pathArr.length === 1) {
    str = `${container}/${realPath}`;
  } else if (pathArr[0] === 'asset') {
    str = realPath.replace('asset::', '').replace('::', '/');
  } else {
    str = realPath.replace('::', '/');
  }
  return str;
}

export function imageUrl(path, container = 'assets') {
  if (path.startsWith('http')) {
    return path;
  }
  const str = getImageRealPath(path, container);
  return `${process.env.API_URL}/${LOCAL_ASSET_PREFIX}/${str}`;
}

export function glideUrl(path, params = {}, container = 'assets') {
  const str = getImageRealPath(path, container);
  const url = Base64.encode(str);
  return `${process.env.API_URL}/${ASSET_PREFIX}/${url}?${param(params)}`;
}

export function glideScrset(path, params = IMAGE_SRCSET, container = 'assets') {
  const str = getImageRealPath(path, container);
  const url = Base64.encode(str);
  return params.map((_param) => `${process.env.API_URL}/${ASSET_PREFIX}/${url}?${param(_param.params)} ${_param.width}`);
}
