import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import type { LooseObject, NullableOrUndefinable } from '@/types/generic';

export function getSelectValue(
  value: NullableOrUndefinable<LooseObject | string>,
  key: string | null = null,
  def: string | boolean | number | null = null
) {
  if (!value) {
    return def;
  }
  if (isString(value) || isNumber(value)) {
    return value;
  }

  return value[key || 'value'] || def;
}

export function ensureArrayValue(value: any, def = []) {
  return Array.isArray(value) ? value : def;
}

export const getSectionProps = () => ({
  section: {
    type: Object as PropType<LooseObject>,
    required: true,
  },
  sectionIndex: {
    type: Number as PropType<number>,
    required: true,
  },
});

export function getSectionButtons(buttons: any, checkLink = false) {
  return ensureArrayValue(buttons).filter((button) => {
    if (checkLink) {
      return !!button.label && !!button.link;
    } else {
      return !!button.label;
    }
  });
}
