/**
 * Custom renderer for Bard + Vue
 * More information about custom renderers here
 * https://vuejs.org/guide/extras/render-function.html
 */

import { h } from 'vue';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import { isUrl, slashed } from '@/utils/url';
import { cleanObject } from '@/utils/clean';
import { glideUrl } from '~/utils/assets';
import AppLink from '~/components/AppLink';
import PtLinkAction from '~/components/global/elements/PtLinkAction.vue';
import type { LooseObject } from '~/types/generic';

const BLOCKS: LooseObject = {
  HEADING: 'heading',
  IMAGE: 'image',
  PARAGRAPH: 'paragraph',
  TABLE: 'table',
  BLOCKQUOTE: 'blockquote',

  BULLET_LIST: 'bulletList',
  CODE_BLOCK: 'codeBlock',
  HARD_BREAK: 'hardBreak',
  LIST_ITEM: 'listItem',
  HR: 'horizontalRule',
  ORDERED_LIST: 'orderedList',
  TABLE_ROW: 'tableRow',
  TABLE_HEADER: 'tableHeader',
  TABLE_CELL: 'tableCell',
};

// legacy bard v1 support
const LEGACY_BLOCKS: LooseObject = {
  bullet_list: BLOCKS.BULLET_LIST,
  ordered_list: BLOCKS.ORDERED_LIST,
  code_block: BLOCKS.CODE_BLOCK,
  hard_break: BLOCKS.HARD_BREAK,
  list_item: BLOCKS.LIST_ITEM,
  horizontal_rule: BLOCKS.HR,
  table_row: BLOCKS.TABLE_ROW,
  table_header: BLOCKS.TABLE_HEADER,
  table_cell: BLOCKS.TABLE_CELL,
};

function getNormalisedNodeRendererFn(nodeRenderer: LooseObject, nodeType: string) {
  const normalisedNodeType = LEGACY_BLOCKS[nodeType] || nodeType;
  return nodeRenderer[normalisedNodeType];
}

const MARKS: LooseObject = {
  BOLD: 'bold',
  CODE: 'code',
  ITALIC: 'italic',
  LINK: 'link',
  SUBSCRIPT: 'subscript',
  UNDERLINE: 'underline',
  STRIKE: 'strike',
  SUPERSCRIPT: 'superscript',
  SPAN: 'span',
};

const isText = (node: any) => node && node.type === 'text';

const nodeAttr = (node: any, attr: string, def: any = null) => (node && node.attrs && node.attrs[attr] ? node.attrs[attr] : def);

const basicMarkRenderer = (h: any, tag: string, attrs: any, key: any, text: any) => {
  return h(
    tag,
    { key, ...attrs },
    {
      default: () => [text],
    }
  );
};

const defaultMarkRenderers: LooseObject = {
  [MARKS.BOLD]: (text: any, attrs: any, key: any, h: any) => basicMarkRenderer(h, 'strong', attrs, key, text),
  [MARKS.ITALIC]: (text: any, attrs: any, key: any, h: any) => basicMarkRenderer(h, 'em', attrs, key, text),
  [MARKS.UNDERLINE]: (text: any, attrs: any, key: any, h: any) => basicMarkRenderer(h, 'u', attrs, key, text),
  [MARKS.CODE]: (text: any, attrs: any, key: any, h: any) => basicMarkRenderer(h, 'code', attrs, key, text),
  [MARKS.SUBSCRIPT]: (text: any, attrs: any, key: any, h: any) => basicMarkRenderer(h, 'sub', attrs, key, text),
  [MARKS.SUPERSCRIPT]: (text: any, attrs: any, key: any, h: any) => basicMarkRenderer(h, 'sup', attrs, key, text),
  [MARKS.STRIKE]: (text: any, attrs: any, key: any, h: any) => basicMarkRenderer(h, 'strike', attrs, key, text),
  [MARKS.SPAN]: (text: any, attrs: any, key: any, h: any) => basicMarkRenderer(h, 'span', attrs, key, text),
  [MARKS.LINK]: (text: any, attrs: any, key: any, h: any) => {
    if (attrs && attrs.href) {
      // swap to nlink if the link is internal
      if (attrs.href.indexOf('/') === 0) {
        return h(
          AppLink,
          {
            ...cleanObject({ ...attrs, href: null, to: slashed(attrs.href) }),
            key,
          },
          {
            default: () => [text],
          }
        );
      }
      // swap to action if the link is action link
      if (attrs.href.indexOf('#') === 0) {
        return h(
          PtLinkAction,
          {
            ...cleanObject({ ...attrs, href: null, action: attrs.href }),
            key,
          },
          {
            default: () => [text],
          }
        );
      }
    }

    return basicMarkRenderer(h, 'a', attrs, key, text);
  },
};

const defaultBlockRenderers: LooseObject = {
  [BLOCKS.PARAGRAPH]: (node: any, key: any, h: any, next: any) => h('p', { key, ...(node.attrs || {}) }, next(node.content, key, h, next)),
  [BLOCKS.HEADING]: (node: any, key: any, h: any, next: any) => h(`h${nodeAttr(node, 'level', '')}`, { key }, next(node.content, key, h, next)),
  [BLOCKS.BULLET_LIST]: (node: any, key: any, h: any, next: any) => h('ul', { key, ...(node.attrs || {}) }, next(node.content, key, h, next)),
  [BLOCKS.ORDERED_LIST]: (node: any, key: any, h: any, next: any) => {
    const attrs = { ...node.attrs };
    if (node.attrs.order) {
      attrs.start = node.attrs.order;
    }
    return h('ol', { key, ...attrs }, next(node.content, key, h, next));
  },
  [BLOCKS.LIST_ITEM]: (node: any, key: any, h: any, next: any) => h('li', { key, ...(node.attrs || {}) }, next(node.content, key, h, next)),
  [BLOCKS.BLOCKQUOTE]: (node: any, key: any, h: any, next: any) => h('blockquote', { key, ...(node.attrs || {}) }, next(node.content, key, h, next)),
  [BLOCKS.HARD_BREAK]: (_node: any, key: any, h: any) => h('br', { key }, {}),
  [BLOCKS.HR]: (_node: any, key: any, h: any) => h('hr', { key }, {}),
  [BLOCKS.IMAGE]: (node: any, _key: any, h: any) => {
    const alt = node.attrs.alt;
    const src = node.attrs.src;
    const url = isUrl(src) ? src : glideUrl(src);
    return h('img', { attrs: { alt, src: url } });
  },
  [BLOCKS.TABLE]: (node: any, key: any, h: any, next: any) =>
    h('table', { key, ...(node.attrs || {}) }, [h('tbody', {}, next(node.content, key, h, next))]),
  [BLOCKS.TABLE_ROW]: (node: any, key: any, h: any, next: any) => h('tr', { key, ...(node.attrs || {}) }, next(node.content, key, h, next)),
  [BLOCKS.TABLE_HEADER]: (node: any, key: any, h: any, next: any) => {
    let attrs = node.attrs || {};
    const style = {};
    if (attrs.background) {
      style['background-color'] = attrs.background;
      attrs = omit(attrs, 'background');
    }
    return h('th', { key, ...attrs, style }, next(node.content, key, h, next));
  },
  [BLOCKS.TABLE_CELL]: (node: any, key: any, h: any, next: any) => {
    let attrs = node.attrs || {};
    const style = {};
    if (attrs.background) {
      style['background-color'] = attrs.background;
      attrs = omit(attrs, 'background');
    }
    return h('td', { key, ...attrs, style }, next(node.content, key, h, next));
  },

  text: ({ marks, text }: any, key: any, h: any, markRenderer: any) => {
    if (marks && marks.length) {
      return marks.reduce((aggregate: any, mark: any, i: number) => {
        const renderer = markRenderer[mark.type] || markRenderer[MARKS.SPAN];
        return renderer(aggregate, mark.attrs || {}, `${key}-${i}`, h);
      }, text);
    }
    return text;
  },
};

const renderNodeList = (nodes: any, key: any, renderer: any) => {
  if (!isArray(nodes)) {
    return [];
  }
  return nodes.map((node, i) => renderNode(node, `${key}-${i}`, renderer));
};

const renderNode = (node: any, key: any, renderer: any) => {
  const nodeRenderer = renderer.node;
  const createElement = renderer.createElement;
  const wrapLinks = renderer.wrapLinks;
  const wrapTextSpan = renderer.wrapTextSpan;

  if (isText(node)) {
    // We're at final tip of node branch, can render text.
    const result = nodeRenderer.text(node, key, createElement, renderer.mark);
    return wrapTextSpan ? createElement('span', [result]) : result;
  }

  const nextNode = (nodes: any) => renderNodeList(nodes, key, renderer);

  if (!nodeRenderer) {
    return createElement('div', `${key} ;lost nodeRenderer`);
  }

  const nodeRendererFn = getNormalisedNodeRendererFn(nodeRenderer, node.type);

  if (!node.type || !nodeRendererFn) {
    return '(Unrecognized node type) ' + (node.type || 'empty');
  }
  return nodeRendererFn(node, key, createElement, nextNode, {
    wrapLinks,
  });
};

const BardContentRenderer = (props: any) => {
  const renderer = {
    node: {
      ...defaultBlockRenderers,
      ...props.blockRenderers,
    },
    mark: {
      ...defaultMarkRenderers,
      ...props.markRenderers,
    },
    wrapLinks: props.wrapLinks || false,
    wrapTextSpan: props.wrapTextSpan || false,
    createElement: h,
  };

  return renderNodeList(props.content, 'BardRenderer-', renderer);
};

BardContentRenderer.props = {
  content: {
    type: Array,
    required: true,
  },
  blockRenderers: {
    type: Array,
    default: () => [],
  },
  markRenderers: {
    type: Array,
    default: () => [],
  },
  wrapLinks: {
    type: Boolean,
    default: false,
  },
  wrapTextSpan: {
    type: Boolean,
    default: false,
  },
};

export default BardContentRenderer;
