<template>
  <div v-if="content" :class="rootClasses">
    <bard-content-renderer v-if="hasBlocks" :content="content" :wrap-links="wrapLinks" :wrap-text-span="wrapTextSpan" />
    <div v-else v-html="content" />
  </div>
</template>

<script lang="ts" setup>
import isArray from 'lodash/isArray';
import BardContentRenderer from '~/components/content/BardContentRenderer';

const props = defineProps({
  content: {
    type: [String, Array],
    default: null,
  },
  lead: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  asContent: {
    type: Boolean,
    default: false,
  },
  primaryLinks: {
    type: Boolean,
    default: false,
  },
  wrapLinks: {
    type: Boolean,
    default: false,
  },
  // Used for the highlight block only
  wrapTextSpan: {
    type: Boolean,
    default: false,
  },
});

const hasBlocks = computed(() => isArray(props.content));

const rootClasses = computed(() => ({
  content: props.asContent,
  'is-lead': props.asContent && props.lead,
  'is-small': props.asContent && props.small,
  'has-p-links': props.asContent && props.primaryLinks,
}));
</script>
